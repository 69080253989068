import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Products from "../components/products"

export const query = graphql`
  query Query($id: ID!) {
    api {
      product(id: $id) {
        id
        slug
        title
        content
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        icon_color {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 80, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

const ProductPage = ({ data, pageContext, location }) => {
  const product = data.api.product
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Offer",
    name: product.title,
    description: product.content.replace(/<\/?[^>]+(>|$)/g, ""),
    image: {
      "@type": "ImageObject",
      url: product.image.url,
    },
  }
  return (
    <Layout>
      <Seo title={`${product.title}`} productStructuredData={structuredData} />
      <Container id="produto">
        <Row>
          <Col>
            <GatsbyImage
              image={product.image.imageFile.childImageSharp.gatsbyImageData}
              alt=""
            />
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <div
              className="d-flex align-items-center"
              style={{ marginTop: -45 }}
            >
              <div>
                <GatsbyImage
                  image={
                    product.icon_color.imageFile.childImageSharp.gatsbyImageData
                  }
                  alt=""
                />
              </div>
              <h2 className="ml-3">{product.title}</h2>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col dangerouslySetInnerHTML={{ __html: product.content }}></Col>
        </Row>
      </Container>
      <Container className="half-padding-top padding-bottom">
        <Products />
      </Container>
    </Layout>
  )
}

export default ProductPage
